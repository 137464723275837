<template>
  <div id="companyEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="donationForUnitData">
              <div class="vx-col w-full">
                <h1 v-if="donationForUnitData && donationForUnitData.id">Uredi možnost donacije za enoto</h1>
                <h1 v-if="!donationForUnitData.id">Dodaj možnost donacije za enoto</h1>
              </div>
            </div>


            <div class="vx-card mt-5 pb-5" v-if="donationForUnitData">
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="grid grid-cols-1">
                    <div class="vx-col w-full" v-if="unitsData">
                      <label for="donorType" class="vs-input--label">Enota</label>
                      <v-select id="donorType" :options="unitsData" class=""
                                v-model="unitId" placeholder="Enota"
                                :reduce="item => item.id"
                                label="name">

                        <template v-slot:option="option">
                          <vs-list-item
                            :title="option.name" :subtitle="option.coveredMunicipalities">
                            <template slot="avatar">
                              <vs-avatar size="large" :text="option.name" class="mr-5"/>
                            </template>
                          </vs-list-item>
                        </template>

                        <template v-slot:selected-option="option">
                          <vs-list-item
                            :title="option.name" :subtitle="option.coveredMunicipalities">
                            <template slot="avatar">
                              <vs-avatar size="large" :text="option.name" class="mr-5"/>
                            </template>
                          </vs-list-item>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                          </template>
                          <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                        </template>
                      </v-select>

                    </div>
                  </div>

                  <div class="grid grid-cols-1">
                    <div class="vx-col w-full mt-5" v-if="donationTypesData">
                      <label for="donationType" class="vs-input--label">Tip donacije za enoto</label>
                      <v-select id="donationType" :options="donationTypesData" class=""
                                v-model="donationTypeId" placeholder="tip donacije"
                                :reduce="item => item.id"
                                @input="filterCompanies"
                                label="name">

                        <template v-slot:option="option">
                          <vs-list-item
                            :title="option.name" :subtitle="option.coveredMunicipalities">
                            <template slot="avatar">
                              <vs-avatar size="large" :text="option.name" class="mr-5"/>
                            </template>
                          </vs-list-item>
                        </template>

                        <template v-slot:selected-option="option">
                          <vs-list-item
                            :title="option.name" :subtitle="option.coveredMunicipalities">
                            <template slot="avatar">
                              <vs-avatar size="large" :text="option.name" class="mr-5"/>
                            </template>
                          </vs-list-item>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                          </template>
                          <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                        </template>
                      </v-select>

                    </div>
                  </div>

                  <div class="grid grid-cols-1">
                    <div class="vx-col w-full mt-5" v-if="filteredCompaniesData">
                      <label for="donorType" class="vs-input--label">Donator</label>
                      <v-select id="donorType" :options="filteredCompaniesData" class=""
                                v-model="companyId" placeholder="Donator"
                                :reduce="item => item.id"
                                label="name">

                        <template v-slot:option="option">
                          <vs-list-item
                            :title="option.company_display_name" :subtitle="option.address.quick_search">
                            <template slot="avatar">
                              <vs-avatar size="large" :text="option.company_display_name" class="mr-5"/>
                            </template>
                          </vs-list-item>
                        </template>

                        <template v-slot:selected-option="option">
                          <vs-list-item
                            :title="option.company_display_name" :subtitle="option.address.quick_search">
                            <template slot="avatar">
                              <vs-avatar size="large" :text="option.company_display_name" class="mr-5"/>
                            </template>
                          </vs-list-item>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                          </template>
                          <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                        </template>
                      </v-select>

                    </div>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <label for="active" class="vs-input--label mb-2">Možnost aktivna</label>
                    <vs-switch class="ml-1" color="success" v-model="active" id="active">
                      <span slot="on">DA</span>
                      <span slot="off">NE</span>
                    </vs-switch>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <label for="active" class="vs-input--label mb-2">Dnevni, ko je donacija na voljo</label>
                    <vs-col v-for="(dayOfWeek, index) in daysOfWeek" :key="index" class="mt-2">
                      <vs-checkbox v-model="selectedDaysOfWeek" :vs-value="dayOfWeek.id">{{
                          dayOfWeek.name
                        }}
                      </vs-checkbox>
                    </vs-col>
                  </div>

                </div>
              </div>
            </div>

            <div class="flex mt-5">
              <div class="w-full">
                <label class="vs-input--label">Opombe</label>
                <vs-textarea class="w-full" rows="6" v-model="notes"/>
              </div>
            </div>

            <div class="flex mt-5">
            <div class="w-1/2">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
                <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
              </vs-col>
            </div>
            <div class="w-1/2">
              <div class="flex">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                  <vs-button size="large" color="danger" icon="delete">Izbriši</vs-button>
                </vs-col>

                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                  <vs-button size="large" color="success" icon="save" @click="saveLunchOption">Shrani</vs-button>
                </vs-col>
              </div>
            </div>
              </div>

          </div>

        </div>
        <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
          <template>


          </template>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
import vSelect from "vue-select";
import Fuse from 'fuse.js'
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

let directionsService = null;
let directionsDisplay = null;
let geoCoder = null;

export default {
  name: 'LunchEdit',

  components: {
    DirectionsRenderer,
    'v-select': vSelect,
    'datepicker': Datepicker,
    'flat-pickr': flatPickr,
  },
  props: {
    lunchOptionId: Number
  },
  data() {
    return {
      currentEditItem: null,
      dayOfWeek: null,
      inAddCronItemMode: false,
      unitsData: [],
      unitId: null,
      companiesData: [],
      filteredCompaniesData: [],
      companyId: null,
      donationTypesData: [],
      donationTypeId: null,
      active: true,
      cronDefinition: null,
      notes: null,
      donationForUnitData: null,
      daysOfWeek: [
        {
          id: 1,
          name: 'ponedeljek'
        },
        {
          id: 2,
          name: 'torek'
        },
        {
          id: 3,
          name: 'sreda'
        },
        {
          id: 4,
          name: 'četrtek'
        },
        {
          id: 5,
          name: 'petek'
        }
      ],
      selectedDaysOfWeek: [],
    }
  },

  computed: {
    getToken() {
      const token = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).token : null;
      if (token) {
        return token
      }
    }
  },

  async mounted() {
    const _this = this;


    console.log("collapse", _this.$refs.collapsible);

    _this.$vs.loading();

    await _this.getUnits();
    await _this.getDonationTypes();
    await _this.getCompanies();

    if (_this.lunchOptionId) {
      await _this.getLunchOptionData();
    } else {
      console.log("kaj pa zdej!?!");
      _this.donationForUnitData = {};
      _this.$vs.loading.close();
    }

    _this.$vs.loading.close();
  },

  watch: {
    lunchOptionId: {
      handler(val, oldVal) {
        console.log("CHANGE!!!!");

        if (val) {
          this.getLunchOptionData();
        }
      }
    }
  },

  methods: {

    filterCompanies() {
      const _this = this;

      _this.companyId = null;

      _this.filteredCompaniesData = _.filter(_this.companiesData,{donor_type_id: _this.donationTypeId});
    },

    async getUnits() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
        .then((res) => {

          _this.unitsData = res.data.data;
          console.log("TOLE: ", res.data.data);

        })
        .catch((error) => {
          console.log("error", error);
          return false
        });
    },

    async getDonationTypes() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'donors/types')
        .then((res) => {

          _this.donationTypesData = _.filter(res.data.data, function(donor) {
            return (donor.id === 1 || donor.id === 2 || donor.id === 8 || donor.id === 9);
          });

          console.log("donorTypes: ", _this.donorTypes);

        })
        .catch((error) => {
          console.log("error", error);
          return false
        });
    },

    async getCompanies() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'donors')
        .then((res) => {

          // get only lunch donors
          _this.companiesData = res.data.data; //_.filter(res.data.data, {donor_type_id: 1});
          _this.filteredCompaniesData = res.data.data;

        })
        .catch((error) => {
          console.log("error", error);
          return false
        });
    },

    async getLunchOptionData() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'daily-donation-schedule/' + _this.lunchOptionId)
        .then((res) => {
          _this.donationForUnitData = res.data.data;
          _this.unitId = res.data.data.unit.id;
          _this.companyId = res.data.data.donor.id;
          _this.active = res.data.data.active;
          _this.notes = res.data.data.notes;
          _this.donationTypeId = res.data.data.type.id;

          let pos = res.data.data.cron.lastIndexOf('*');
          let days = res.data.data.cron.substring(pos + 2, res.data.data.cron.length);
          let selectedDaysOfWeek = days.split(',');
          _.each(selectedDaysOfWeek, function (val) {
            _this.selectedDaysOfWeek.push(parseInt(val));
          });
        })
        .catch((error) => {
          console.log("error", error);
          return false
        });
    },

    saveLunchOption() {
      const _this = this;

      _this.$vs.loading();

      let data = {
        "unit": _this.unitId,
        "donationTypeId": _this.donationTypeId,
        "donor": _this.companyId,
        "active": _this.active,
        "cron": "* * * * " + _this.selectedDaysOfWeek.toString(),
        "notes": _this.notes,
      }

      if (_this.lunchOptionId) {
        Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'daily-donation-schedule/' + _this.lunchOptionId, data)
          .then((res) => {
            console.log("res", res);
            _this.$vs.loading.close();
            _this.$router.push({name: 'DonacijeZaEnote'});
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      } else {
        Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'daily-donation-schedule', data)
          .then((res) => {
            console.log("res", res);
            _this.$vs.loading.close();
            _this.$router.push({name: 'DonacijeZaEnote'});
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      }
    }

  }
}
</script>

