<template>
    <div id="companyContainer">
      <vs-breadcrumb class="mb-5">
        <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
        <li aria-disabled="page" class="disabled-link">Donacije<span class="vs-breadcrum--separator">/</span></li>
        <li><router-link to="/donacije/donacije-za-enote">Donacije za enote</router-link><span class="vs-breadcrum--separator">/</span></li>
        <li aria-current="page" class="active">{{lunchOptionId ? 'Urejanje' : 'Dodajanje'}} donacije za enoto</li>
      </vs-breadcrumb>

      <DonationForUnitEdit :lunch-option-id="lunchOptionId"></DonationForUnitEdit>
    </div>
</template>

<script>
  import Vue from 'vue';
  import DonationForUnitEdit from "../../components/Sopotniki/donations/DonationForUnitEdit";

  export default {
    name: 'LunchesEdit',

    components: {
      DonationForUnitEdit
    },

    data() {
      return {
        lunchOptionId: this.$route.params.lunchOptionId ? parseInt(this.$route.params.lunchOptionId) : null,
      }
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.lunchOptionId = null;
      setTimeout(() => {next()}, 100);
    },

    computed: {},

    mounted() {
      const _this = this;
    },

    methods: {}
  }
</script>

